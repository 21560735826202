import { MdArrowOutward } from "react-icons/md";
import style from "./CareersListing.module.css";
import { remoteConfig } from "firebase.js";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { useEffect, useState } from "react";
export default function CareersListing({ careers }) {
  const [careerEmail, setCareerEmail] = useState("");
  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const email = getValue(remoteConfig, "careerEmail");
        console.log(email);
        setCareerEmail(email._value);
      })
      .catch((err) => {});
  });
  if (careers.length === 0) {
    return (
      <div className={style.parent}>
        <div className={style.notHiring}>
          Currently Not Sifting Through Resumes (We appreciate your interest!
          Keep an eye out for future opportunities.)
        </div>
      </div>
    );
  }
  return (
    <div className={style.parent}>
      <div className={style.containert}>
        {careers.map((e) => (
          <ListCard key={e.id} data={e.data} careerEmail={careerEmail} />
        ))}
      </div>
    </div>
  );
}

function ListCard({ data, careerEmail }) {
  console.log(careerEmail);
  return (
    <div className={style.listCard}>
      <div className={style.listCardContent}>
        <div className={style.listCardTitle}>{data.title}</div>
        <div className={style.listCardDescription}>{data.description}</div>
        <div className={style.listCardPillContainer}>
          {data.tags.map((tag) => (
            <div key={tag} className={style.listCardPill}>
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div className={style.listCardApplyContent}>
        <a href={ `mailto:${careerEmail}?subject=Application for ${data.title}&body=I would like to apply for the position of ${data.title}`} className={style.listCardApplyButton}>
          Apply <MdArrowOutward />
        </a>
      </div>
    </div>
  );
}
